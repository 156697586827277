import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "“Fast” development is really fast?",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:55:24.000Z",
  "url": "/2018/02/fast-development-is-really-fast/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`{.content-preview-title}`}</h1>
    <p>{`Fast development is not fast! it seems fast at first but believe me its super slow`}{`…`}</p>
    <p>{`I work at an awesome remote position, the company take super challenging products and starts them from the ground up.`}</p>
    <p>{`Few months ago I joined a project in the making (2 months after it started), and had to master all the frontend side with 2 other developers that started learning react with this project.`}</p>
    <p>{`We started noticing some problems as the product managers were super pressured to launch the product. All the pressured came down on us the developers.`}</p>
    <p>{`What do I mean by pressure is like:`}</p>
    <ul>
      <li parentName="ul">{`you get a feature, you estimate it as 3 days, and you are told to finish it in one day. (?!)`}</li>
    </ul>
    <p>{`All of those things could be properly handled with you really keep your foot down, and explain what would happen in the future.`}</p>
    <p>{`I notified the team lead about it, and he knew about it, but he had to ship so he decided to go with the flow and simply ship as they requested.`}</p>
    <p>{`Some hard facts of the current project:`}</p>
    <ul>
      <li parentName="ul">{`It was suppose to be `}{`“`}{`live`}{`”`}{` 2 months ago, and we are still in the making.`}</li>
      <li parentName="ul">{`application is super buggy and just now the PM`}{`’`}{`s understand the cost of what I mentioned before.`}</li>
    </ul>
    <p>{`I can surely say its my fault that I didn`}{`’`}{`t put more pressure on the matter and described what would happen if we continue on the same path.`}</p>
    <p>{`I gave in to the `}{`“`}{`pressure`}{`”`}{` to deliver, and simply coded to ship, and not coded so it will work properly with edge cases, tests etc.`}</p>
    <h6>{`Always plan before you code! {#alwaysplanbeforeyoucode}`}</h6>
    <p>{`Take your task, break it down to tiny bits of subtasks, write down an estimate for each subtask and glue it together with some overhead.`}</p>
    <p>{`Make sure to do it on paper! with all the amazing apps, todo lists, task management etc. Pen and paper at the start is the way to go`}{`…`}</p>
    <p>{`Believe me, just try it and you will see.`}</p>
    <h6>{`Take this guidelines from this post: {#takethisguidelinesfromthispost}`}</h6>
    <ol>
      <li parentName="ol">{`Take a pen and paper`}</li>
      <li parentName="ol">{`Break your feature to small subtasks`}</li>
      <li parentName="ol">{`Estimate each feature`}</li>
      <li parentName="ol">{`Glue them together with some overhead to get a rough estimate for that feature`}</li>
    </ol>
    <h6>{`React Feature Guidelines: {#reactfeatureguidelines}`}</h6>
    <ol>
      <li parentName="ol">{`Take a pen and paper 😀 (always`}{`…`}{`)`}</li>
      <li parentName="ol">{`Wireframe your feature and break it to small components`}</li>
      <li parentName="ol">{`Make sure to reuse existing components from the rest of the app`}</li>
      <li parentName="ol">{`Plan you view/dumb components, props etc`}</li>
      <li parentName="ol">{`Plan your container components`}</li>
      <li parentName="ol">{`Using redux? plan your state design`}</li>
      <li parentName="ol">{`Start building the View components, that`}{`’`}{`s the easy part, make sure to have tests!!!`}</li>
      <li parentName="ol">{`Build container component, and have tests!!`}</li>
      <li parentName="ol">{`Make sure your components are documented so other devs could reuse them somewhere else. Use PropTypes!!!!!!`}</li>
    </ol>
    <p>{`There is much more to the flow, but just wanted to have a morning post that will blast all my thoughts for future reference 🙂`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      